













import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class InsSlideMenu extends Vue {
  @Prop({ default: 'left' }) private direction !: string;
  // private direction: string = ''; // 菜單滑出方向: 'top', 'left', 'right'
  private width: string = ''; // 菜單寬度：'top'默認'100%'，僅在position值非'top'的情況生效
  private height: string = ''; // 菜單最大高度：僅在position值'top'的情況生效，其餘值默認'100%'
  // private Embedded: boolean = this.FrontE.slideMenu.Embedded || false; // 菜單是否內嵌 => 默認值 false ，僅在position值為非'top'的情況生效

  // 設置內嵌菜單的相關高度
  setMenuHeight () {
    let top = 0;
    if (!this.showFixedHeader) {
      top = (document.getElementById('header') as HTMLElement).clientHeight;
    } else {
      top = (document.querySelector('.FixedHeader') as HTMLElement).clientHeight;
    }

    let bodyHeight = document.documentElement.clientHeight;

    console.log(bodyHeight, 'bodyHeight', top, 'top');

    // this.height = (bodyHeight - top) + 'px';
    // (document.querySelector('.sidebar-container') as HTMLElement).style.top = top + 'px';
    // (document.querySelector('.drawer-bg') as HTMLElement).style.height = (bodyHeight - top) + 'px';
    // (document.querySelector('.drawer-bg') as HTMLElement).style.top = top + 'px';
  }

  // 是否處於顯示固定頭部狀態
  get showFixedHeader () {
    return this.$store.state.showFixedHeader;
  }

  // 是否顯示滑動側欄菜單
  get showMenu () {
    return this.$store.state.isShowMenu;
  }

  handleClickOutside () {
    this.$store.dispatch('isShowMenu', false);
  }

  // created () {
  //   // 設置菜單參數值
  //   this.direction = this.FrontE.slideMenu.direction || 'left';
  //   this.width = this.direction !== 'left' && this.FrontE.slideMenu.width ? this.FrontE.slideMenu.width : '100%';
  //   if (!this.Embedded) {
  //     this.height = this.direction === 'left' && this.FrontE.slideMenu.height ? this.FrontE.slideMenu.height : '100%';
  //   } else {
  //     // console.log((document.getElementById('header') as HTMLElement).clientHeight, '頭部高度id');
  //     // console.log((document.querySelector('.header-box') as HTMLElement).clientHeight, '頭部高度querySelector');

  //     // let top = (document.getElementById('header') as HTMLElement).clientHeight;
  //     // let bodyHeight = document.body.clientHeight;

  //     // this.height = (bodyHeight - top) + 'px';
  //   }
  // }

  mounted () {
    window.addEventListener('resize', () => {
      if (this.showMenu) {
        this.setMenuHeight();
      }
    });
  }

  @Watch('showMenu', { deep: true })
  onShowMenu (val) {
    if (val) {
      document.body.style.overflow = 'hidden';

      // if (this.Embedded && this.direction !== 'left') {
      this.setMenuHeight();
      // }
    } else {
      document.body.style.overflow = 'auto';
    }
  }
}
